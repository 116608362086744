import {
    SET_ROUTES, SET_SERVICES
} from "./types";

const initialState = {
    routes: [],
    services: []
}

export const RoutesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROUTES:
            return { ...state, routes: action.payload }
        case SET_SERVICES:
            return { ...state, services: action.payload }
        default:
            return state
    }
}