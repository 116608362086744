import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDelete, getUsers } from '../../store/Auth/actions'
import * as AuthSelectors from "../../store/Auth/selectors"
import { DialogAction, Table } from '../../components'
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function Users() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const [id, setId] = useState(null)

    const users = useSelector(AuthSelectors.users)

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const del = (item) => {
        return (
            <IconButton color="error" onClick={() => {
                setId(item.id)
                setOpen(true)
            }}>
                <DeleteIcon />
            </IconButton>
        )
    }

    const next = (item) => {
        navigate(`/users/${item}`)
    }

    const deleteUser = () => {
        dispatch(getDelete(id))
    }

    return (
        <>
            <Table data={users} cols={[
                { title: 'ID', code: 'id', type: 'link', fun: next },
                { title: 'ФИО', code: 'name' },
                { title: 'Почта', code: 'email' },
                { title: 'Телефон', code: 'phone' },
                { title: 'Дата создания', code: 'created_at', type: 'dateTime' },
                { title: '', val: del }
            ]} />
            <DialogAction open={open} setOpen={setOpen} title="Удаление пользователя" text="Вы уверены что хотите удалить пользователя?" agree="Удалить" action={() => deleteUser()} />
        </>
    )
}

export default Users