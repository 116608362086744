import { Fab, IconButton } from '@mui/material'
import { DialogAction, Table } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone';
import "./style.css"
import { useEffect, useState } from 'react';
import EditForm from './EditForm';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoute, getRoutes, getServices } from '../../store/Routes/actions';
import * as RoutesSelector from "../../store/Routes/selectors"
import AddForm from './AddForm';

const Routes = () => {
    document.title = "Маршруты"

    const routes = useSelector(RoutesSelector.routes)
    const services = useSelector(RoutesSelector.services)

    const container = window.innerWidth;

    const [open, setOpen] = useState(false);
    const [modalDelete, setModalDelete] = useState(false)
    const [id, setId] = useState(0)

    const [openEdit, setOpenEdit] = useState(false);
    const [route, setRoute] = useState([])
    const [group, setGroup] = useState('')
    const [_index, setIndex] = useState(0)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRoutes())
        dispatch(getServices())
    }, [])

    const index = (item, index) => {
        return index + 1
    }

    const service = (item, index) => {
        return item.actions.service
    }

    const path = (item, index) => {
        return item.actions.path
    }

    const deleteButton = (item, index) => {
        return <IconButton
            aria-label="delete"
            onClick={(e) => {
                e.stopPropagation()
                setModalDelete(true)
                setId(index)
            }}
            sx={{
                width: '25px',
                height: '25px',
                color: "#f60607",
            }}
        >
            <DeleteIcon />
        </IconButton>
    }

    let cols = []

    if (container < 600) {
        cols = [
            { title: 'Маршрут', code: 'path' },
            { title: 'Сервис', val: service },
            { title: '', val: deleteButton }
        ]
    } else {
        cols = [
            { title: "№", val: index },
            { title: 'Метод', code: 'method' },
            { title: 'Маршрут', code: 'path' },
            { title: 'Сервис', val: service },
            { title: 'Маршрут', val: path },
            { title: '', val: deleteButton }
        ]
    }

    const openEditModal = (route, i) => {
        setOpenEdit(true)
        setRoute(route)
        setIndex(i)
    }

    return <>
        {
            routes.length !== 0 ? routes.group.map((item, i) => (
                <div key={i}>
                    <h4>{item.name}</h4>
                    <Table cols={cols} data={item.route} onRowClick={(route, i) => {
                        openEditModal(route, i)
                        setGroup(item.name)
                    }} />
                </div>
            )) : null
        }

        <h4>Без группы</h4>
        <Table cols={cols} data={routes.length !== 0 ? routes.route : []} onRowClick={(item) => { setOpenEdit(true); setRoute(item) }} />

        <Fab color='custom_purple' aria-label="add" sx={{
            position: 'fixed',
            bottom: '50px',
            right: '50px'
        }} onClick={() => setOpen(true)}>
            <AddIcon />
        </Fab>
        <AddForm
            group={group}
            index={_index}
            services={services ?? []}
            open={open}
            setOpen={e => setOpen(e)}
        />
        <EditForm
            current={route}
            group={group}
            index={_index}
            services={services ?? []}
            open={openEdit}
            setOpen={e => setOpenEdit(e)}
        />
        <DialogAction
            title="Удалить"
            text="Вы уверены что хотите удалить данный маршрут?"
            agree="Удалить"
            open={modalDelete}
            setOpen={e => setModalDelete(e)}
            action={() => dispatch(deleteRoute({
                group: "v1",
                key: id
            }))}
        />
    </>
}

export default Routes