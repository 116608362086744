import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Wrapper } from "./components";
import { Auth, Home, Routes as RoutesScreen, Services, UserInfo, Users } from "./screens";
import { getUser, setAuth } from "./store/Auth/actions";
import * as AuthSelector from "./store/Auth/selectors"


function App() {
    const auth = useSelector(AuthSelector.auth)
    const token = useSelector(AuthSelector.token)

    const dispatch = useDispatch()

    useEffect(() => {
        if (token) {
            dispatch(getUser())
        } else {
            dispatch(setAuth(false))
        }
    }, [])

    if (auth === null) return <></>

    return (
        <Wrapper drawer_width={auth ? 240 : 0}>
            {
                auth ? <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/routes" element={<RoutesScreen />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/:id" element={<UserInfo />} />
                </Routes> : <Auth />
            }
        </Wrapper>
    )
}

export default App;
