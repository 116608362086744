import { Box, IconButton, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo } from '../../store/Auth/actions'
import { useParams } from 'react-router-dom'
import * as AuthSelectors from "../../store/Auth/selectors"
import moment from 'moment'


function UserInfo() {
    const { id } = useParams()
    const dispatch = useDispatch()

    const userInfo = useSelector(AuthSelectors.userInfo)

    useEffect(() => {
        dispatch(getUserInfo(id))
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '65%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography component="h3" sx={{ fontSize: '18px', fontWeight: '500' }}>Общая информация</Typography>
                    <IconButton sx={{
                        ml: 'auto'
                    }}>
                        <MoreVertIcon />
                    </IconButton>
                </Box>
                <TableContainer>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>id</TableCell>
                                <TableCell align="right">{userInfo.id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>ФИО</TableCell>
                                <TableCell align="right">{userInfo.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Телефон</TableCell>
                                <TableCell align="right">{userInfo.phone}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Почта</TableCell>
                                <TableCell align="right">{userInfo.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Дата добавления</TableCell>
                                <TableCell align="right">{moment(userInfo.created_at).format("DD.MM.YYYY HH:mm")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Дата обновления</TableCell>
                                <TableCell align="right">{moment(userInfo.updated_at).format("DD.MM.YYYY HH:mm")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ width: '35%', pl: '24px' }}>
                <Box sx={{
                    backgroundColor: "#f9f8fd",
                    width: '100%',
                    borderRadius: '25px',
                    boxShadow: '5px 5px 8px 0px rgb(0 0 0 / 20%)',
                    p: '24px'
                }}>
                    <Typography component="h3" sx={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: '500' }}>
                        Компании:
                        <IconButton sx={{
                            ml: 'auto'
                        }}>
                            <MoreVertIcon />
                        </IconButton>
                    </Typography>
                    <List>
                        {
                            typeof userInfo.companys_info !== "undefined" && userInfo.companys_info.map(item => <ListItem disableGutters secondaryAction={
                                <IconButton color='error' onClick={() => { }}>
                                    <HighlightOffIcon />
                                </IconButton>
                            }>
                                <ListItemText primary={item.title} />
                            </ListItem>)
                        }
                    </List>
                </Box>
                <Box sx={{
                    backgroundColor: "#f9f8fd",
                    width: '100%',
                    borderRadius: '25px',
                    boxShadow: '5px 5px 8px 0px rgb(0 0 0 / 20%)',
                    p: '24px',
                    mt: 2
                }}>
                    <Typography component="h3" sx={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: '500' }}>
                        Роли:
                        <IconButton sx={{
                            ml: 'auto'
                        }}>
                            <MoreVertIcon />
                        </IconButton>
                    </Typography>
                    <List>
                        {
                            typeof userInfo.roles_info !== "undefined" && userInfo.roles_info.map(item => <ListItem disableGutters secondaryAction={
                                <IconButton color='error' onClick={() => { }}>
                                    <HighlightOffIcon />
                                </IconButton>
                            }>
                                <ListItemText primary={item.title} />
                            </ListItem>)
                        }
                    </List>
                </Box>
            </Box>
        </Box>
    )
}

export default UserInfo