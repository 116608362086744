import React, { useState } from 'react'
import { createTheme, Drawer, Fab, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ThemeProvider, Toolbar, Typography, Box } from "@mui/material"
import Dashboard from '@mui/icons-material/Dashboard'
import Route from '@mui/icons-material/Route'
import Usb from '@mui/icons-material/Usb'
import People from '@mui/icons-material/People'
import Settings from '@mui/icons-material/Settings'
import ExitToApp from '@mui/icons-material/ExitToApp'
import { useDispatch } from 'react-redux'
import { logOut } from "../../store/Auth/actions"
import { useLocation, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import DialogAction from '../DialogAction'
import GroupsIcon from '@mui/icons-material/Groups'

function Wrapper({ drawer_width, children }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [mobileOpen, setMobileOpen] = useState(false)
    const [exit, setExit] = useState(false)

    const location = useLocation()

    const drawerWidth = drawer_width;
    const menu = [
        {
            icon: <Dashboard />,
            text: 'Dashboard',
            url: '/'
        },
        {
            icon: <Route />,
            text: 'Маршруты',
            url: '/routes'
        },
        {
            icon: <Usb />,
            text: 'Сервисы',
            url: '/services'
        },
        {
            icon: <People />,
            text: 'Пользователи',
            url: '/users'
        },
        {
            icon: <GroupsIcon />,
            text: 'Группы',
            url: '/groups'
        }
    ]

    const theme = createTheme({
        palette: {
            custom_purple: {
                main: '#5955b3',
                light: '#5955b3',
                dark: '#5955b3',
                contrastText: '#fff'
            },
        }
    })

    const MenuList = () => (
        <>
            <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: '#fff',
                        textDecoration: 'none',
                    }}>
                    SG v3
                </Typography>
            </Toolbar>
            <List>
                {menu.map((item, index) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton onClick={() => navigate(item.url)} selected={(location.pathname.includes(item.url) && item.url !== '/') || (location.pathname === item.url)} sx={{
                            "&.Mui-selected": {
                                backgroundColor: '#5955b3',
                                "& .MuiListItemIcon-root": {
                                    color: "#fff"
                                },
                                "& .MuiListItemText-primary": {
                                    color: "#fff"
                                }
                            }
                        }}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} sx={{ color: "#b9b7ff", fontWeight: 'Light' }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <List sx={{ marginTop: "auto" }}>
                <ListItem disablePadding>
                    <ListItemButton selected={location.pathname === '/settings'} sx={{
                        "&.Mui-selected": {
                            backgroundColor: '#5955b3',
                            "& .MuiListItemIcon-root": {
                                color: "#fff"
                            },
                            "& .MuiListItemText-primary": {
                                color: "#fff"
                            }
                        }
                    }} >
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText primary="Настройки" sx={{ color: "#b9b7ff", fontWeight: 'Light' }} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => setExit(true)}>
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText primary="Выход" sx={{ color: "#b9b7ff", fontWeight: 'Light' }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    )

    const container = window !== undefined ? () => window.document.body : undefined;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="body">
                <Box sx={{ display: 'flex' }}>
                    <Box
                        component="nav"
                        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                        aria-label="mailbox folders"
                    >
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: "#5955b3", border: "none" },
                            }}
                        >
                            <MenuList />
                        </Drawer>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', sm: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: "#5955b3", border: "none" },
                            }}
                            open
                        >
                            <MenuList />
                        </Drawer>
                    </Box>
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, height: '100vh', display: 'flex', width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                    >
                        <Box sx={{
                            flex: 1, bgcolor: "#fff", borderRadius: '35px', margin: '5px', marginLeft: 0, overflow: 'hidden', overflowY: 'scroll', padding: '30px',
                            "&::-webkit-scrollbar": {
                                width: 0
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#b9b7ff",
                                borderRadius: '5px'
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#5955b3",
                                borderRadius: '5px'
                            },
                            position: "relative"
                        }}>
                            <Fab color='custom_purple' aria-label="add" sx={{
                                position: 'fixed',
                                top: '15px',
                                left: '15px',
                                display: { xs: 'inline-flex', sm: 'none' }
                            }} onClick={handleDrawerToggle}>
                                <MenuIcon />
                            </Fab>
                            {children}
                        </Box>
                    </Box>
                </Box>
            </div>
            <DialogAction
                title="Выход"
                text="Вы уверены что хотите выйти?"
                agree="Выйти"
                open={exit}
                setOpen={e => setExit(e)}
                action={() => dispatch(logOut())}
            />
        </ThemeProvider>
    )
}

export default Wrapper