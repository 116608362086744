import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, RadioGroup, TextField } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { addRoute } from '../../store/Routes/actions';

function AddForm({ group = '', index, services = [], open, setOpen }) {
    const dispatch = useDispatch()
    const [apiGroup, setApiGroup] = useState('v1');
    const [apiRoute, setApiRoute] = useState('');
    const [service, setService] = useState('machine');
    const [url, setUrl] = useState('');

    const [get, setGet] = useState(false)
    const [post, setPost] = useState(false)
    const [put, setPut] = useState(false)
    const [_delete, setDelete] = useState(false)

    const [auth, setAuth] = useState(true)

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = () => {
        let body = {
            group: apiGroup,
            method: [],
            path_api: apiRoute,
            middleware: [],
            service: service,
            path: url
        }

        if (group !== '') body.push('group', apiGroup)

        if (get) body.method.push('GET')
        if (post) body.method.push('POST')
        if (put) body.method.push('PUT')
        if (_delete) body.method.push('DELETE')

        if (auth) body.middleware.push('auth')

        dispatch(addRoute(body))
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <DialogTitle>
                Добавить
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Группа"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={apiGroup}
                        onChange={e => setApiGroup(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Маршрут Api"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={apiRoute}
                        onChange={e => setApiRoute(e.target.value)}
                    />
                    <FormLabel id="radio-buttons-group-label">Тип запроса</FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="GET" control={<Checkbox checked={get} onChange={() => setGet(!get)} />} label="GET" />
                        <FormControlLabel value="POST" control={<Checkbox checked={post} onChange={() => setPost(!post)} />} label="POST" />
                        <FormControlLabel value="PUT" control={<Checkbox checked={put} onChange={() => setPut(!put)} />} label="PUT" />
                        <FormControlLabel value="DELETE" control={<Checkbox checked={_delete} onChange={() => setDelete(!_delete)} />} label="DELETE" />
                    </RadioGroup>
                    <TextField
                        id="select-currency-native"
                        margin="dense"
                        select
                        label="Сервис"
                        value={service}
                        onChange={e => setService(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        {services.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Маршрут до сервиса"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                    />
                    <FormControlLabel value="Авторизация" control={<Checkbox checked={auth} onChange={() => setAuth(!auth)} />} label="Авторизация" />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleUpdate(false)}>Добавить</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddForm