import api from "../../services/api";

import {
    SET_ROUTES, SET_SERVICES
} from "./types";


export const setRoutes = data => {
    return {
        type: SET_ROUTES,
        payload: data
    }
}

export const setServices = data => {
    return {
        type: SET_SERVICES,
        payload: data
    }
}


export const getRoutes = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/routes')

        if (response.status === 200) {
            const json = await response.data
            dispatch(setRoutes(json))
        }
    } catch (e) {
        console.error(e)
    }
}

export const getServices = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/service')

        if (response.status === 200) {
            const json = await response.data

            const list = []

            for (let key in json) {
                list.push({
                    value: key,
                    label: key,
                    url: json[key]
                })
            }

            dispatch(setServices(list))
        }
    } catch (e) {
        console.error(e)
    }
}

export const deleteRoute = (item) => async (dispatch, getState) => {
    try {
        const response = await api.delete('/api/routes', {
            data: item
        })

        if (response.status === 200) {
            setTimeout(() => {
                dispatch(getRoutes())
            }, 3000);
        }
    } catch (e) {
        console.error(e)
    }
}

export const addRoute = (item) => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/routes', item)

        if (response.status === 201) {
            setTimeout(() => {
                dispatch(getRoutes())
            }, 3000);
        }
    } catch (e) {
        console.error(e)
    }
}