import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import { AuthReducer } from "./Auth/reducers"
import { RoutesReducer } from "./Routes/reducers"

const rootReducers = combineReducers({
    AuthReducer,
    RoutesReducer
})

export default createStore(rootReducers, applyMiddleware(thunk))