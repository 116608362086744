import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, RadioGroup, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

function EditForm({ current = [], group = '', index, services = [], open, setOpen }) {
    const [apiRoute, setApiRoute] = useState('');
    const [service, setService] = useState('');
    const [url, setUrl] = useState('');

    const [get, setGet] = useState(false)
    const [post, setPost] = useState(false)
    const [put, setPut] = useState(false)
    const [_delete, setDelete] = useState(false)

    const [auth, setAuth] = useState(true)


    useEffect(() => {
        if (current.length !== 0) {
            setApiRoute(current.path)
            setService(current.actions.service)
            setUrl(current.actions.path)
            setGet(current.method.indexOf('GET') !== -1 ? true : false)
            setPost(current.method.indexOf('POST') !== -1 ? true : false)
            setPut(current.method.indexOf('PUT') !== -1 ? true : false)
            setDelete(current.method.indexOf('DELETE') !== -1 ? true : false)
            if (current.middleware) {
                setAuth(current.middleware.indexOf('auth') !== -1 ? true : false)
            } else {
                setAuth(false)
            }
        }
    }, [current])

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = () => {
        console.log(
            apiRoute,
            service,
            url,
            get,
            post,
            put,
            _delete,
            group,
            auth,
            index
        )
    }

    if (current.length === 0) return null

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <DialogTitle>
                Редактирование
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Маршрут Api"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={apiRoute}
                        onChange={e => setApiRoute(e.target.value)}
                    />
                    <FormLabel id="radio-buttons-group-label">Тип запроса</FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="GET" control={<Checkbox checked={get} onChange={() => setGet(!get)} />} label="GET" />
                        <FormControlLabel value="POST" control={<Checkbox checked={post} onChange={() => setPost(!post)} />} label="POST" />
                        <FormControlLabel value="PUT" control={<Checkbox checked={put} onChange={() => setPut(!put)} />} label="PUT" />
                        <FormControlLabel value="DELETE" control={<Checkbox checked={_delete} onChange={() => setDelete(!_delete)} />} label="DELETE" />
                    </RadioGroup>
                    <TextField
                        id="select-currency-native"
                        margin="dense"
                        select
                        label="Сервис"
                        value={service}
                        onChange={e => setService(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        {services.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Маршрут до сервиса"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                    />
                    <FormControlLabel value="Авторизация" control={<Checkbox checked={auth} onChange={() => setAuth(!auth)} />} label="Авторизация" />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleUpdate(false)}>Обновить</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditForm