import { Grid } from '@mui/material';
import React from 'react'
import { Box } from '@mui/system';

function Home() {

    return (
        <Grid container sx={{
            height: '100%'
        }}>
            <Grid container
                columnSpacing={2}
                sx={{ height: '50%' }}>
                <Grid item xs={8}>
                    <Box sx={{
                        backgroundColor: "#f9f8fd",
                        height: '447px',
                        width: '100%',
                        borderRadius: '25px',
                        boxShadow: '5px 5px 8px 0px rgb(0 0 0 / 20%)',
                        overflowY: 'scroll',
                        "&::-webkit-scrollbar": {
                            width: 0
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "#b9b7ff",
                            borderRadius: '5px'
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#5955b3",
                            borderRadius: '5px'
                        },
                    }}>
                        {/* список запросов */}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{
                        backgroundColor: "#f9f8fd",
                        height: '447px',
                        width: '100%',
                        borderRadius: '25px',
                        boxShadow: '5px 5px 8px 0px rgb(0 0 0 / 20%)'
                    }}>
                        {/* Кол-во запрсов */}
                    </Box>
                </Grid>
            </Grid>
            <Grid container
                rowSpacing={2}
                columnSpacing={2}
                sx={{ height: '50%' }}>
                <Grid item xs={4}>
                    <Box sx={{
                        backgroundColor: "#f9f8fd",
                        height: '443px',
                        width: '100%',
                        borderRadius: '25px',
                        boxShadow: '5px 5px 8px 0px rgb(0 0 0 / 20%)'
                    }}>
                        {/* нагрузка */}
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{
                        backgroundColor: "#f9f8fd",
                        height: '443px',
                        width: '100%',
                        borderRadius: '25px',
                        boxShadow: '5px 5px 8px 0px rgb(0 0 0 / 20%)',
                        overflowY: 'scroll',
                        "&::-webkit-scrollbar": {
                            width: 0
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "#b9b7ff",
                            borderRadius: '5px'
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#5955b3",
                            borderRadius: '5px'
                        },
                    }}>
                        {/*  map */}
                    </Box>
                </Grid>
            </Grid>

        </Grid >
    )
}

export default Home