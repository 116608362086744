import { Fab, IconButton } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../components';
import { getServices } from '../../store/Routes/actions';
import * as RoutesSelector from "../../store/Routes/selectors"
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone';
import AddIcon from '@mui/icons-material/Add';

function Services() {
    const services = useSelector(RoutesSelector.services)

    const dispatch = useDispatch()

    const container = window.innerWidth;

    useEffect(() => {
        console.log(container)
        dispatch(getServices())
    }, [])

    const index = (item, index) => {
        return index + 1
    }

    const deleteButton = (item, index) => {
        return <IconButton
            aria-label="delete"
            onClick={(e) => {
                e.stopPropagation()
            }}
            sx={{
                width: '25px',
                height: '25px',
                color: "#f60607",
            }}
        >
            <DeleteIcon />
        </IconButton>
    }

    let cols = []

    if (container < 600) {
        cols = [
            { title: 'Код', code: 'label' },
            { title: '', val: deleteButton }
        ]
    } else {
        cols = [
            { title: "№", val: index },
            { title: 'Код', code: 'label' },
            { title: 'Адресс', code: 'url' },
            { title: '', val: deleteButton }
        ]
    }

    return (
        <>
            <Table cols={cols} data={services.length !== 0 ? services : []} />
            <Fab color='custom_purple' aria-label="add" sx={{
                position: 'fixed',
                bottom: '50px',
                right: '50px'
            }}>
                <AddIcon />
            </Fab>
        </>
    )
}

export default Services