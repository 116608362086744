import api from "../../services/api";

import {
    SET_AUTH,
    SET_AUTH_EMAIL,
    SET_AUTH_PASSWORD,
    SET_EXPRES_IN,
    SET_TOKEN,
    SET_USER,
    SET_USERS,
    SET_USER_INFO
} from "./types";


export const setEmail = data => {
    return {
        type: SET_AUTH_EMAIL,
        payload: data
    }
}

export const setPassword = data => {
    return {
        type: SET_AUTH_PASSWORD,
        payload: data
    }
}

export const setToken = data => {
    return {
        type: SET_TOKEN,
        payload: data
    }
}

export const setExpires_in = data => {
    return {
        type: SET_EXPRES_IN,
        payload: data
    }
}

export const setAuth = data => {
    return {
        type: SET_AUTH,
        payload: data
    }
}

export const setUser = data => {
    return {
        type: SET_USER,
        payload: data
    }
}

export const setUserInfo = data => {
    return {
        type: SET_USER_INFO,
        payload: data
    }
}

export const setUsers = data => {
    return {
        type: SET_USERS,
        payload: data
    }
}

export const authUser = () => async (dispatch, getState) => {
    const email = getState().AuthReducer.email
    const password = getState().AuthReducer.password

    try {
        const response = await api.post('/api/auth/login', {
            email: email,
            password: password
        })

        if (response.status === 200) {
            const { access_token, expires_in } = await response.data

            localStorage.setItem("token", access_token)
            localStorage.setItem("expires_in", expires_in)

            dispatch(setToken(access_token))
            dispatch(setExpires_in(expires_in))
            dispatch(setAuth(true))
        }

    } catch (e) {
        console.error(e)
    }
}

export const logOut = () => async (dispatch, getState) => {
    try {
        const response = await api.post('/api/auth/logout', {})

        if (response.status === 200) {
            localStorage.setItem("token", '')
            localStorage.setItem("expires_in", '')

            dispatch(setToken(''))
            dispatch(setExpires_in(''))
            dispatch(setAuth(false))
        }

    } catch (e) {
        console.error(e)
    }
}

export const getUser = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/user', {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setUser(json))
            dispatch(setAuth(true))
        }

    } catch (e) {
        console.error(e)
    }
}

export const getUsers = () => async (dispatch, getState) => {
    try {
        const response = await api.get('/api/users', {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setUsers(json))
        }

    } catch (e) {
        console.error(e)
    }
}

export const getDelete = (id) => async (dispatch, getState) => {
    try {
        const response = await api.delete(`/api/user/${id}`, {})

        if (response.status === 200) {
            dispatch(getUsers())
        }

    } catch (e) {
        console.error(e)
    }
}

export const getUserInfo = (id) => async (dispatch, getState) => {
    try {
        const response = await api.get(`/api/user/${id}`, {})

        if (response.status === 200) {
            const json = await response.data

            dispatch(setUserInfo(json))
        }

    } catch (e) {
        console.error(e)
    }
}